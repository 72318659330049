import { useEffect, useState, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { getDataByClienteId } from "../../actions/consulSteel";
import banner from '../../assets/images/banner_consulsteel.png';
import logo from '../../assets/images/logo_consulsteel.svg';
import Dolar from "../../routes/dolar";
import colores from "../../shared/constants/styles";
import Title from "../commons/header_title";
import Card from "./card";

const MyConsulSteel = (props: any) => {
    const dispatch = useDispatch();
    const localStorageItem = window.localStorage.getItem("LocalSession");
    const localStorage = localStorageItem ? JSON.parse(localStorageItem) : null;
    const [clienteSelected, setClienteSelected] = useState(null);
    const [errorUI, seterrorUI] = useState({
        category: "",
        date: "",
    });

    useEffect(() => {
        dispatch(getDataByClienteId(localStorage.clienteId));
    }, []);

    useEffect(() => {
        if(props.data){
            console.log(props.data)
        }
    }, [props.data]);

    return (
        <div style={{
                backgroundColor: colores.grisClaro,
                overflow: "hidden",
                clear: "both" }}>
            <div style={{ width: "100%" }}>
                <img src={banner} style={{ width: "100%" }} alt="banner" />
            </div>
            <div>
                <Dolar></Dolar>
                {/* <Title title={"EN CURSO"}></Title> */}
                <div style={{ width: "100%", textAlign: "center" }}>
                    <img src={logo} style={{ width: "12%" }} alt="logo" />
                </div>
            </div>
            <div
                className="container-fluid mx-0 px-0 w-100"
                style={{ margin: "2rem" }}
            >
                <div
                className="container-fluid"
                style={{
                    backgroundColor: "white",
                    width: "100%",
                    overflow: "hidden",
                    clear: "both",
                    marginBottom: "1.85rem",
                    paddingLeft: "76px",
                    paddingRight: "76px",
                }}
                >
                    {props?.loading ? (
                    <div className="container-fluid w-50  d-flex justify-content-center">
                        <h4>Carregando...</h4>
                    </div>
                    ) : (
                    ""
                    )}
                    {
                    <div className="d-flex flex-wrap">
                        {props.data?.map((data: any) => (
                            <Card data={data} />
                        ))}
                    </div>
                    }
                </div>
            </div>
        </div>    
    );
};

export default connect(
    (state: any) => ({
        data: state.consulsteel.data,
        loading: state.consulsteel.loading
    }),
    (dispatch) => ({
        getDataByClienteId: (clienteId: string) => dispatch(getDataByClienteId(clienteId)),
    })
)(MyConsulSteel);
