import { connect, useDispatch } from "react-redux";
import { getEstadoPedidoByFilter } from "../../../actions/cuentacorriente";
import { useEffect, useState } from "react";
import { ESTADO_PEDIDOS_COLUMN } from "./interface/columnData";
import cuentaCorrienteService  from "../../../services/cuentacorriente";
import Filter from "./filter";
import TrackingPedido from "./tracking_pedido";
import TableCustom from "../../../common/components/table/table";
import IFilterEstadoPedido from "./interface/IFilterEstadoPedido";
import IClienteIdFilter from "../../../common/interface/IClienteIdFilter";
import Descarga from "../descarga";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";

const EstadoPedidos = (props: any) => {
    const dispatch = useDispatch();
    const localStorage = JSON.parse(window.localStorage.getItem("LocalSession") || '{}');
    const [loading, setLoading] = useState<boolean>(false);
    const [clienteFilter, setClienteFilter] = useState<IClienteIdFilter>({
        clienteId: localStorage.clienteId.trim()
    })
    const history = useHistory();
    const [filter, setFilter] = useState<IFilterEstadoPedido>({
        dateFrom: null,
        dateTo: null,
        estadoId: null,
        presupuestoId: null,
        clienteId: localStorage.clienteId.trim(),
        page: 0,
        rowPerPage: 5
    })
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [popUp, setPopUp] = useState(false);
    const [pedidoId, setPedidoId]= useState<string>("");

    const getEstadoPedido = (page: number, rowsPerPage: number): void => {
        filter.rowPerPage= rowsPerPage;
        filter.page= page;
        dispatch(getEstadoPedidoByFilter(filter));
    };
    const getFilterEstadoPedido = ():void => {
        setPage(0);
        setRowsPerPage(5);
        filter.rowPerPage= 5;
        filter.page= 0;
        dispatch(getEstadoPedidoByFilter(filter));
    }
    
    useEffect(() => {
        dispatch(getEstadoPedidoByFilter(filter));
    }, []);

    // useEffect(() => {
    //     console.log(props.estadopedidos)
    //     // if (props.estadopedidos.data) {
    //     //     setAcopios(props.acopios?.data.$values);
    //     // }
    // }, [props]);

    const handleDownload = async () => {
        setLoading(true);
        const response = await cuentaCorrienteService.downloadFileEstadoPedidos(clienteFilter);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'estado_pedidos.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoading(false);
    };

    const handleNuevoReclamo = async (item: any) => {
        history.push(`/nuevo-reclamo/${item.facturaId}`);
    };

    const handleVerTracking = async (item: any) => {
        setPedidoId(item.pedidoId);
        setPopUp(!popUp);
    };

    const handleClose= () => {
        setPopUp(!popUp);
    }

    return (
        <div style={{ margin: "2vw" }}>
            {popUp && (
                <TrackingPedido handleClose={handleClose} pedidoId={pedidoId}></TrackingPedido>
            )}
            <div className="row">
                <div className="col-10">
                    <Filter 
                        getFilterEstadoPedido={getFilterEstadoPedido}
                        filter={filter}
                        onSelectedFilter={setFilter}
                    />                 
                </div>   
                <div className="col-2">
                    <Descarga loading={loading} handleDownload={handleDownload}></Descarga>               
                </div>   
            </div>
            
            <TableCustom page={page} 
                    onSelectedPage={setPage} 
                    rowsPerPage={rowsPerPage} 
                    onSelectedRowPerPage={setRowsPerPage} 
                    items={props.estadopedidos?.estadoPedidosResponses}
                    length={props.estadopedidos?.length} 
                    getItems={getEstadoPedido}
                    handleNuevoReclamo={handleNuevoReclamo}
                    handleVerTracking={handleVerTracking}
                    columns={ESTADO_PEDIDOS_COLUMN}
                />
        </div>
    );
};
export default connect(
    (state: any) => ({
        estadopedidos: state.cuentacorriente.estadopedidos,
        error: state.cuentacorriente.error,    
        loading: state.cuentacorriente.loading
    }),
    (dispatch) => ({
        getEstadoPedidoByFilter: (filter: IFilterEstadoPedido) => dispatch(getEstadoPedidoByFilter(filter))
    })
)(EstadoPedidos);
