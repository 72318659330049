import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { isAllowed } from '../../../shared/utils/permissions';
import colores from '../../../shared/constants/styles';
import TokenService from '../../../services/token';
import rutas from '../../../routes/constants/routes';

const MenuPerfil = (props: any) => {
    const { Nombre, identificationId, numeroDoc, email } = props.usuario;

    const clienteSeleccionado = window.localStorage.getItem('selectedClient') ? JSON.parse(window.localStorage.getItem('selectedClient') as string) : null;

    const opcionesPerfil =
    [
        {   
            titulo: 'Meu perfil',
            link: `/cliente/perfil`,
            permiso: 21
        },
        {   
            titulo: 'Mudar a senha',
            link: `/changepassword/${numeroDoc}/${identificationId}/${email}`,
            permiso: 22
        },
        {   
            titulo: 'Contato',
            link: `/formulario_contacto`,
            permiso: 22
        }
    ]

    const BorrarSession = () => {
        localStorage.removeItem('LocalSession');
        localStorage.removeItem('selectedClient');
        TokenService.clear();
        window.location.assign(rutas.LOGIN)
    }
    
    return (
        <li className="nav-item dropdown my-auto" style={{marginRight:'0.7rem'}}>
            <Link className="nav-link dropdown-toggle" style={{textDecoration: 'none', color: 'white'}} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {clienteSeleccionado ? `${Nombre} (${clienteSeleccionado[clienteSeleccionado?.length - 1]?.nombreFantasia})` : Nombre} 
            </Link>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" style={{backgroundColor: colores.rojo}}>
                {opcionesPerfil?.map( o => ( isAllowed(o.permiso) &&
                    <li><Link className="dropdown-item" href="#" 
                        to={o.link} style={{textDecoration: 'none', color: 'white'}}
                    >{o.titulo}</Link></li> 
                )
                )}
                <li><Link className="dropdown-item" href="#" 
                        onClick={BorrarSession}
                        style={{textDecoration: 'none', color: 'white'}}
                    >Sair</Link></li>
            </ul>
        </li> 
);};

export default withRouter(connect((state) => ({}))(MenuPerfil));
