import React from "react";
import colores from "../shared/constants/styles";
import Insta from "../assets/icons/Insta.svg";
import Facebook from "../assets/icons/Facebook.svg";
import Twitter from "../assets/icons/Twitter.svg";
import Youtube from "../assets/icons/Youtube.svg";
import Linkedin from "../assets/icons/Linkedin.svg";
import afip from "../assets/images/afip.jpg";
import barbieriMarca from "../assets/images/Barbieri-MarcaGrafica-Footer.svg";
import { Link } from "react-router-dom";
import rutas from "./constants/routes";

const FooterPage = () => {
  const style = { background: colores.grisOscuro, color: "white" };

  return (
    <footer style={style} className="p-5">
      <div className="row d-flex justify-content-around">
        {/* <div className="col-lg-1 col-md-2 mb-4"><img className="mx-3" style={{width:64, height:64}} src={sustentable} alt=""></img>
          </div> */}

        <div className="col-lg-3 col-md-3 d-block-sm mb-3">
          <h5>Onde estamos?</h5>
          <div>
            <p>
            Rodovia dos Minérios, 4560, CEP 83511-530 -Bairro Botiatuba, Almirante Tamandaré<br></br>Paraná, Brasil.
            </p>
            <img style={{ width: 84, height: 116 }} src={afip} alt=""></img>
          </div>
        </div>

        <div className="col-lg-3 col-md-3 d-block-sm mb-3">
          <div style={style}>
            <ul style={{ listStyle: "none" }}>
              <img
                style={{ width: 164, height: 28.21, marginBottom: 15 }}
                src={barbieriMarca}
                alt=""
              ></img>
              <li>
                <a
                  style={{ textDecoration: "none" }}
                  href="https://www.adbarbieri.com/pt-br/empresa"
                  target="blank"
                >
                  <span style={{ color: "white" }}>Quem somos</span>
                </a>
              </li>
              {/* <li><a style={{textDecoration:"none"}} href="https://www.clubbarbieri.com.ar/" target="blank"><span style={{color:"white"}}>Club Barbieri</span></a></li> */}
              <li>
                  <span style={{ color: "white" }}>Produtos e serviços</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 d-block-sm mb-3">
          <ul style={{ listStyle: "none", listStylePosition: "inside" }}>
            <h5>Serviço ao cliente</h5>
            <li>
              <a
                style={{ textDecoration: "none" }}
                href="https://portalventas.br.adbarbieri.com/#/formulario_contacto"
                target="blank"
              >
                <span style={{ color: "white" }}>Contato</span>
              </a>
            </li>
          </ul>
          <div className="mx-4 my-4">
            <a href="https://www.instagram.com/barbieriarg/" target="blank">
              <img
                className="mx-1 my-1"
                style={{ width: 25, height: 25 }}
                src={Insta}
                alt=""
              ></img>
            </a>
            <a href="https://www.facebook.com/adbarbierisa" target="blank">
              <img
                className="mx-1 my-1"
                style={{ width: 25, height: 25 }}
                src={Facebook}
                alt=""
              ></img>
            </a>
            <a href="https://twitter.com/barbieriarg" target="blank">
              <img
                className="mx-1 my-1"
                style={{ width: 25, height: 25 }}
                src={Twitter}
                alt=""
              ></img>
            </a>
            <a href="http://www.youtube.com/Barbieri1953" target="blank">
              <img
                className="mx-1 my-1"
                style={{ width: 25, height: 25 }}
                src={Youtube}
                alt=""
              ></img>
            </a>
            <a
              href="https://www.linkedin.com/company/ad-barbieri-sa"
              target="blank"
            >
              <img
                className="mx-1 my-1"
                style={{ width: 25, height: 25 }}
                src={Linkedin}
                alt=""
              ></img>
            </a>
          </div>
        </div>
      </div>
      <span>
        <hr></hr>
      </span>
      <span style={{ fontSize: 14 }}>
       Todos os direitos reservados AD Barbieri ©2020
      </span>
    </footer>
  );
};

export default FooterPage;
