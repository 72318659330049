import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Dolar from "../../routes/dolar";
import Title from "../commons/header_title";
import colores from "../../shared/constants/styles";
import Novedad from "./novedad";
import { getNovedadesByClienteId } from "../../actions/novedades";

const Novedades = (props) => {
  const local = JSON.parse(window.localStorage.getItem("LocalSession"));
  const [novedades, setNovedades] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNovedadesByClienteId(local.clienteId.trim()));
  }, []);

  useEffect(() => {
    if (props.novedades.data) {
      setNovedades(props.novedades?.data.$values);
    }
  }, [props.novedades]);

  return (
    <div
      style={{
        backgroundColor: colores.grisClaro,
        overflow: "hidden",
        clear: "both",
      }}
    >
      <div>
        {/* <Dolar></Dolar> */}
        <Title title={"Novidades"}></Title>
      </div>
      <div
        className="container-fluid mx-0 px-0 w-100"
        style={{ margin: "2rem" }}
      >
        <div
          className="container-fluid"
          style={{
            backgroundColor: "white",
            width: "100%",
            overflow: "hidden",
            clear: "both",
            marginBottom: "1.85rem",
            paddingLeft: "76px",
            paddingRight: "76px",
          }}
        >
          <div style={{ margin: "2vw" }}>
            {props?.loading ? (
              <div
                style={{ marginTop: "10vh", marginBottom: "4vh" }}
                className="container-fluid w-50  d-flex justify-content-center"
              >
                <h4>Carregando...</h4>
              </div>
            ) : (
              ""
            )}
            {!props?.loading && novedades.length <= 0 ? (
              <div
                style={{ marginTop: "10vh", marginBottom: "4vh" }}
                className="container-fluid w-50  d-flex justify-content-center"
              >
                <h4>Nenhuma Novidades</h4>
              </div>
            ) : (
              <div className="d-flex flex-wrap" style={{ margin: "5vh" }}>
                {novedades.map((novedad) => (
                  <Novedad key={novedad.id} data={novedad} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    novedades: state.novedades.novedades,
    loading: state.novedades.loading,
  }),
  (dispatch) => ({
    getNovedadesByClienteId: (clienteId) =>
      dispatch(getNovedadesByClienteId(clienteId)),
  })
)(Novedades);
