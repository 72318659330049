import React, {useEffect} from 'react';
import { connect, useDispatch } from 'react-redux';
import { requestVendedor } from '../../actions/vendedor';
import {
    Container,
    Grid,
} from '@material-ui/core';
import colores from '../../shared/constants/styles';
import rutas from "../../routes/constants/routes";
import { useHistory, useParams } from 'react-router';
import ClienteVendedor from "./clientevendedor.js"
import Cliente from '../clientes/cliente';

const SellerProfile = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { user_id, vendedor , clientes } = props
    const vendedorid = props.match.params.id

    useEffect(() => {        
        dispatch(requestVendedor(vendedorid))
    }, [dispatch])


    const handleEdit= () => {
        history.push(`/changepassword/${vendedor.identification}/${vendedor.identificationType.id}`)
    }

    return (
        <>
        <Container className="py-4" component="main" maxWidth="lg">
            <div className="py-4 row">
                <Grid item lg={2} sm={3} xs={12} className="my-3"><h1>MEU PERFIL</h1></Grid>
                <Grid item sm={4} xs={12}>
                    <button 
                        className="btn btn-danger float-start" 
                        onClick = { () => handleEdit() }
                        type="button" 
                        style={{backgroundColor: "#E3000B", padding: "0.625rem", marginTop:"1.25rem", inlineSize:"15rem"}}
                    > 
                        Cambiar Contraseña 
                    </button>
                </Grid>
            </div>

            <div style={{marginLeft: "2rem"}}>

                <Grid container spacing={5} >
                    <Grid item xs={6} >
                        <div className="my-3"><h3>Mis datos</h3></div>
                    </Grid>
                </Grid>
                <Grid className="row">
                    <Grid container item md={4} sm={12} className="my-3">
                        <Grid item className="atributoTitle">N° de usuario:</Grid><Grid item>   </Grid>
                        <Grid item className="atributoDato"> {vendedor?.id}</Grid>
                    </Grid>
                    <Grid container item md={4} sm={12} className="my-3">
                        <Grid item className="atributoTitle">Tipo de documento: </Grid>
                        <Grid item className="atributoDato"> {vendedor?.identificationType?.name}</Grid>
                    </Grid>
                    <Grid container item md={4} sm={12} className="my-3">
                        <Grid item className="atributoTitle">N°: </Grid>
                        <Grid item className="atributoDato"> {vendedor?.identification}</Grid>
                    </Grid>
                    <Grid container item md={4} sm={12} className="my-3">
                        <Grid item className="atributoTitle">Nombre: </Grid>
                        <Grid item className="atributoDato"> {vendedor?.nombreFantasia}</Grid>
                    </Grid>
                    <Grid container item md={4} sm={12} className="my-3">
                        <Grid item className="atributoTitle">Email:</Grid>
                        <Grid item className="atributoDato">{vendedor?.email}</Grid>
                    </Grid>
                    <Grid container item md={4} sm={12} className="my-3">
                        <Grid item className="atributoTitle">Teléfono: </Grid>
                        <Grid item className="atributoDato"> {vendedor?.telefono}</Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={5} >
                    <Grid item xs={6} >
                        <div className="my-3"><h3>Clientes</h3></div>
                    </Grid>
                </Grid>
                <Grid container spacing={5} >
                    {vendedor?.clientesResponse?.$values.map(cliente => (<ClienteVendedor key={cliente.id} data={cliente}/>))}
                </Grid>

            </div>

        </Container>
        </>
    )
}

export default connect(
    state => ({
        vendedor: state.vendedor.vendedor,
        loading: state.vendedor.loading
    }),
    dispatch => ({
        requestVendedor: (id) => dispatch(requestVendedor(id))
    })
  )(SellerProfile);