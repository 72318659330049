import {useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getReclamosByCuit, getReclamosByFilter, getEstadosReclamo } from '../../actions/reclamos';
import Reclamo from './reclamo';
import Dolar from '../../routes/dolar';
import colores from '../../shared/constants/styles';
import { Container, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MyUserService from "../../services/myUser";

const styles = makeStyles(() => ({
  multipleSelect: {
    "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
      backgroundColor: "rgb(227, 0, 11);",
      color: "white",
    },
  },
}));

const Reclamos = (props) => {

  const classes = styles();
  const myUser = MyUserService.getMyUser();
  const [reclamos, setReclamos] = useState([]);
  const [estados, setEstados] = useState([]);
  const [estadoId, setEstadoId] = useState([]);
  const [errorUI, seterrorUI] = useState({
    category: "",
    date: "",
  });
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const dispatch = useDispatch()  
  useEffect(() => {
    dispatch(getReclamosByCuit(myUser?.documentoNro.trim()));  
    dispatch(getEstadosReclamo()); 
  }, [])

  useEffect(() => {
    if(props.reclamos.data){
      setReclamos(props.reclamos?.data.$values);
    }  
  }, [props.reclamos])

  useEffect(() => {
    if(props.estados.data){
      setEstados(props.estados?.data.$values);
    }  
  }, [props.estados])
 
  const [filtro, setFiltro] = useState({
    cuit: myUser?.documentoNro.trim(),
    dateFrom: null,
    dateTo: null,
    estadoId: null
  })

  const handleChangeEstado = ({ target }) => {
    setFiltro({
      ...filtro,
      estadoId : target.value
    })
    setEstadoId(target.value);
  };

  const handleDateFromChange = (newDate) => {
    setFiltro({
      ...filtro,
      dateFrom : newDate
    })
    setDateFrom(newDate);
  };

  const handleDateToChange = (newDate) => {
    setFiltro({
      ...filtro,
      dateTo : newDate
    })
    setDateTo(newDate);
  };

  const handleClean= () => {
    setEstadoId(null);
    setDateTo(null);
    setDateFrom(null);
    setFiltro({
      cuit: myUser?.documentoNro.trim(),
      dateFrom: null,
      dateTo: null,
      estadoId: null
    })
    dispatch(getReclamosByCuit(myUser?.documentoNro.trim()));  
  }

  const buscar = (event) => {
    event.preventDefault();
    dispatch(getReclamosByFilter(filtro));
  }

  return (
    <div style={{ backgroundColor:colores.grisClaro, overflow:"hidden", clear:"both"}}>
      <div>
        {/* <Dolar></Dolar> */}
        {/* HEADER */}
        <div className="container-fluid" style={{width: "100%", overflow:"hidden", clear:"both", marginBottom: "1.85rem"}}>

            <div className='d-flex justify-content-center'>
              <hr style={{width:"90%", color:"black", marginTop: "0"}}></hr>
            </div>
          <Container maxWidth="w-100" style={{paddingRight:"60px",paddingLeft:"60px"}}>
            <div className='d-flex justify-content-between flex-wrap align-items-center ' style={{marginBottom:"0.600rem", paddingRight:"3.5rem"}}>
              <h1 className="fw-bolder" style={{fontSize: "bolder"}}>MINHAS RECLAMAÇÕES</h1>
            </div>        
          </Container>
        </div>
        {/* FILTRO Y LISTADO */}
        <div className='container-fluid mx-0 px-0 w-100' style={{margin:"2rem"}}>
          <div className="container-fluid" style={{backgroundColor: "white", width: "100%", overflow:"hidden", clear:"both", marginBottom: "1.85rem",paddingLeft:"76px",paddingRight:"76px"}}>
            <div className='d-flex justify-content-between' style={{margin:"2rem 0"}}>
              <div>
                <h2>FILTROS</h2>
              </div>
            </div>
            <form className="row row-cols-1 row-cols-sm-2 row-cols-md-3" onSubmit={buscar} style={{margin:"1rem 0.2rem", padding: 0}}>
                <div className='d-flex align-items-end'>
                  <div className='col-10'>
                    <FormControl fullWidth>
                        <InputLabel id="estadoId">Estados</InputLabel>
                        <Select                          
                          labelId="estadoId"
                          id="estados"
                          value={estadoId}
                          label="Estados"
                          className={classes.multipleSelect}
                          onChange={handleChangeEstado}
                        >
                          {estados?.map((estado) => (
                            <MenuItem value={estado.idInterno}>{estado.nombre}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                  </div>
                </div>
                <div className='d-flex align-items-end'>
                    <div  className="row">
                      <div className="col-6">
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <LocalizationProvider
                              adapterLocale="es"
                              dateAdapter={AdapterDayjs}
                            >
                              <MobileDatePicker
                                label="Desde"
                                inputFormat="DD/MM/YYYY"
                                value={dateFrom}
                                onChange={handleDateFromChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={Boolean(errorUI?.date)}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                      </div>
                      <div className="col-6">
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <LocalizationProvider
                              adapterLocale="es"
                              dateAdapter={AdapterDayjs}
                            >
                              <MobileDatePicker
                                label="Até"
                                inputFormat="DD/MM/YYYY"
                                value={dateTo}
                                onChange={handleDateToChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={Boolean(errorUI?.date)}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                      </div>
                    </div>
                </div>           
              <div className='d-flex align-items-end'>
                <button type="submit" style={{background: colores.rojo, color: 'white'}} class="btn">Buscar</button>
                <button style={{background: colores.white, color: 'red', borderColor: 'red', marginLeft: '2vh'}} class="btn" onClick={handleClean}>Limpar</button>
              </div>
            </form>                
            <div className='container-fluid px-0' >
              <div >
                  { props?.loading ? 
                    
                    <div style={{marginTop: '10vh', marginBottom: '4vh'}} className='container-fluid w-50  d-flex justify-content-center'>
                      <h4>Carregando...</h4>
                    </div> 
                    : 
                      ""
              
                  }
                { !props?.loading && reclamos.length <= 0 ?
                   <div style={{marginTop: '10vh', marginBottom: '4vh'}} className='container-fluid w-50  d-flex justify-content-center'>
                      <h4>No existen reclamos para el filtro aplicado</h4>
                    </div> 
                :(
                  <div className='d-flex flex-wrap'>
                    {reclamos?.map(reclamo => (<Reclamo key={reclamo.id} data={reclamo}/>))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect
(
  state => ({
    reclamos: state.reclamos.reclamos,
    estados: state.reclamos.estados,
    loading: state.reclamos.loading,
  }),
  dispatch => ({
    getReclamosByCuit: (cuit) => dispatch(getReclamosByCuit(cuit)),
    getReclamosByFilter: (filter) => dispatch(getReclamosByFilter(filter)),
    getEstadosReclamo: () => dispatch(getEstadosReclamo()),
  })
)(Reclamos);