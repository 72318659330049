import axios from 'axios';
import  TokenService  from './token';

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/Client/`


export default class clientesService {

    static async contact(formData) {
        console.log(formData);
        const respuesta = await axios.post(`${ENDPOINT}contactForm`, formData)
        .then(response => {
            return response
        })  
        return respuesta.data.data
    }

    static async fetch() {
        const respuesta = await axios.get(`${ENDPOINT}all`)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values
    }

    // Recordar cambio en el fetch
    static async fetchAll() {
        const respuesta = await axios.get(`${ENDPOINT}all`)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values
    }

    static async fetchBySeller(id) {
        const respuesta = await axios.get(`${ENDPOINT}getAllBySellerId?idSeller=${id}`)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values
    }

    static async find(id, contactoId) {
        debugger
        const respuesta = await axios.get(`${ENDPOINT}${id}/${contactoId}`)
        .then(response => {
            return response
        })  
        return respuesta.data.data
    }

    static async getClienteByNroDoc(nroDoc, tipoDoc) {
        const respuesta = await axios.get(`${ENDPOINT}GetClienteByNroDoc?nroDoc=${nroDoc}&tipoDoc=${tipoDoc}`)
        .then(response => {
            return response
        })  
        return respuesta.data.data
    }

    static async search(search) {
        const respuesta = await axios.get(`${ENDPOINT}search?name=${search}`)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values
    }

    static async updateAddress(updateAddressRequest) {
        const respuesta = await axios.post(`${ENDPOINT}updateAddress`, updateAddressRequest)
        .then(response => {
            return response
        })  
        return respuesta.data.data
    }
}
