import { connect } from 'react-redux';
import { saveVendedor } from '../../actions/vendedor';
import {
    Container,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from '@material-ui/core';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from 'react-router';

const NuevoVendedor = (props) => {
    const {docType, regions} = props;
    const formik = useFormik({
        initialValues: {
            name: "",
            lastName: "",
            identificationTypes: "",
            identification: "",
            phone: "",
            email: "",
            totVsCode: "",
            region: ""
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            lastName: Yup.string().required(),
            identificationTypes: Yup.string().required(),
            identification: Yup.string().required(),
            phone: Yup.string().required(),
            email: Yup.string().required(),
            totVsCode: Yup.string().required(),
            region: Yup.string().required()
    }),
        onSubmit: (vendedor) => {
          props.saveVendedor(vendedor);
        }
      })
    const history = useHistory();
    const handleBack = () => {
        history.goBack();
    }
    if (props.error) return <h1>Error de conexion con el BE.</h1>;
    if (props.loading) return <h1>Carregando todo...</h1>;
    if (props.saving) return <h1>Guardando...</h1>;
    
    return (
        <Container className="py-4" component="main" maxWidth="lg">
            <div className="py-5"><h1>NUEVO VENDEDOR</h1></div>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={6}>
                    <Grid item xs={6}>
                        <TextField  
                            required
                            fullWidth
                            id="name"
                            label="Nombre"
                            name="name"
                            autoComplete="name"
                            onChange={formik.handleChange}
                            error={formik.errors.name && true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField  
                            required
                            fullWidth
                            id="lastName"
                            label="Apellido"
                            name="lastName"
                            autoComplete="lastName"
                            onChange={formik.handleChange}
                            error={formik.errors.lastName && true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="identificationTypesLabel">Tipo</InputLabel>
                                    <Select
                                    defaultValue=""
                                    labelId="identificationTypesLabel"
                                    id="demo-simple-select"
                                    label="Tipo de documento"
                                    name="identificationTypes"
                                    required
                                    onChange={formik.handleChange}
                                    error={formik.errors.identificationTypes && true}
                                    >
                                    {docType?.map((element, index) => {
                                        return <MenuItem key={index} value={element.id}>{element.name}</MenuItem>
                                    })
                                    }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={8}>
                            <TextField  
                                required
                                fullWidth
                                id="identification"
                                label="Numero de documento"
                                name="identification"
                                autoComplete="identification"
                                onChange={formik.handleChange}
                                error={formik.errors.number && true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField  
                            required
                            fullWidth
                            id="phone"
                            label="Telefono"
                            name="phone"
                            autoComplete="phone"
                            onChange={formik.handleChange}
                            error={formik.errors.phone && true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField  
                            required
                            fullWidth
                            id="email"
                            label="Mail"
                            name="email"
                            autoComplete="email"
                            onChange={formik.handleChange}
                            error={formik.errors.email && true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField  
                            required
                            fullWidth
                            id="totVsCode"
                            label="Código TOTVS"
                            name="totVsCode"
                            autoComplete="totVsCode"
                            onChange={formik.handleChange}
                            error={formik.errors.totVsCode && true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="identificationTypesLabel">Región</InputLabel>
                            <Select
                            defaultValue=""
                            labelId="identificationTypesLabel"
                            id="region"
                            label="Región"
                            name="region"
                            required
                            onChange={formik.handleChange}
                            error={formik.errors.region && true}
                            >
                            {regions?.map((element, index) => {
                                return <MenuItem key={index} value={element.id}>{element.name}</MenuItem>
                            })
                            }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => handleBack()}
                        >
                            Volver
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid> 
            </form>
        </Container>
    );
}

export default connect(
  state => ({
    saving: state.vendedor.saving,
    docType: state.params.docType,
    regions: state.params.regions,
    loading: state.params.loading,
    error: state.params.error
  }),
  dispatch => ({
    saveVendedor: (vendedor) => dispatch(saveVendedor(vendedor))
  })
)(NuevoVendedor);