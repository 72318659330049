import {useEffect,useState} from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { requestOneUsersIdentification } from '../actions/users'
import { hasProyectByClienteId } from '../actions/consulSteel'
import { Link } from "react-router-dom";
import logo from '../assets/images/Barbieri-MarcaGrafica-01.png';
import rutas from './constants/routes';
import colores from '../shared/constants/styles';
import MyUserService from '../services/myUser';
import { requestPresupuestoDetalles } from '../../src/actions/presupuesto';
import SearchBar from '../utils/SearchBar';
import { requestProductosAllSearch } from '../../src/actions/producto';
import './header.css'
import MenuIco from   '../assets/icons/menu.svg'
import MenuNovedades from '../pages/commons/menu-items/novedades';
import MenuPerfil from '../pages/commons/menu-items/perfil';

const Header = (props) => {
    const history = useHistory();
    const [success, setSuccess] = useState(false);
    const [search, setSearch] = useState(false);
    const [navOpen, setNavOpen] = useState(true)
    const [showConsulsteelMenu, setShowConsulsteelMenu] = useState(false);
    const myUser = MyUserService.getMyUser();
    const dispatch = useDispatch();
    const menu = myUser?.menus?.$values ?? null;
    const menuPadre = menu?.filter(m => m.menuPadreId === null) ?? null ;
    const menuHijo = menu?.filter(m => m.menuPadreId !== null) ?? null ;
    const usuario = {  
        ultimoIngreso: myUser?.ultimoLogin,
        email: myUser?.email,
        Nombre: myUser?.tipoRol === 1 ? "Administrador" : ( (myUser?.bussinessName !== null) ? myUser?.bussinessName : myUser?.userName),
        Id: myUser?.userId,
        identificationId: myUser?.identificationId,
        numeroDoc: myUser?.documentoNro
    }

    const handleClick = (m, h) => {
        if(h.download){
            downloadFile(h.link, h.nombre.trim());
        } else {
            if(h.redirect){
                window.open(h.link, '_blank');
            } else {
                var newurl = window.location.protocol + "//" + window.location.host + "/#"  + m.link + h.link;
                window.history.pushState({path:newurl},'',newurl);
                window.location.reload();
            }
        }
    }    
    useEffect(() => {
        dispatch(requestPresupuestoDetalles(myUser?.contactoId, myUser?.clienteId))
    }, [props?.success]);

    useEffect(() => {
        dispatch(hasProyectByClienteId(myUser?.clienteId))
    }, []);

    useEffect(() => {
        if(props?.success_productos){
            setSearch(false);
            // Redirigir a otra ruta con la lista como parámetro
            setSearchTerm('');
            history.push(`/allProductsList/${encodeURIComponent(JSON.stringify(props?.productos))}`);
            setSuccess(false);
        }
    }, [props])

    useEffect(() => {
        if(props?.hasproject && props?.hasproject_success){
            setShowConsulsteelMenu(true);
        }
    }, [props.hasproject])

    // useEffect(() => {
    //     setSuccess(props?.success_productos)
    // }, [props?.success_productos])
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (searchTerm) => {
        setSearch(true);
        props.requestProductosAllSearch(searchTerm, myUser.clienteId)
    };
    
    const downloadFile = (link, nombre) => {
        
        //`${ENDPOINT}download/manualmarca`
        const fileName = nombre+ ".zip"; 
        fetch(link)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement("a");
                a.href = url;
                a.download = fileName;
                a.click();
            })
            .catch(error => console.error("Error downloading file:", error));
    };
    return (
        <>
        <div style={{background: colores.rojo, padding:'1rem', width:"100%"}}>
            {
            myUser ?
            (   
                <div>
                    <div className="fluid" style={{display:'flex', justifyContent:'flex-end', textDecoration:'none', color:'white', width:"100%", marginRight:"3rem"}}>
                        { usuario.ultimoIngreso ? (<p style={{fontSize:'12px', paddingInlineEnd:"4rem", marginBottom:"0rem"}}>Último acesso: {usuario.ultimoIngreso}</p>) : <p style={{fontSize:'12px', paddingInlineEnd:"4rem", marginBottom:"0rem"}}>Bienvenido/a</p> }
                    </div>

                    <nav className="navbar navbar-expand-lg static-top header__nav-container" style={{padding:'0rem'}}>
                        <div className="fluid" style={{justifyContent: 'space-between', padding:'0rem', width:"100%", margin:"0rem 3.5rem"}}>
                            <div className="row">
                                <div className='col-2'>
                                    <Link className={navOpen ? "navbar-brand header__nav-logo" : "navbar-brand"} href="#" to={rutas.DASHBOARD}>
                                        <img src={logo} alt="Barbieri logo"  height="36"></img>
                                    </Link>
                                    <img className='header__ico-menu' src={MenuIco} alt='Menu Ico' onClick={ () => setNavOpen(!navOpen) }></img>
                                </div>
                                <div className='col-10'>
                                    {/* <SearchBar search={search} success={success} onSearch={handleSearch} searchTerm={searchTerm} setSearchTerm={setSearchTerm} /> */}
                                </div>
                            </div>                            
                            <div className={navOpen ? "collapse.show navbar-collapse" : "collapse navbar-collapse"} id="navbarSupportedContent"> 
                                <ul className="navbar-nav ms-auto">
                                    {menuPadre?.map( m => 
                                        menuHijo?.some(mh => mh.menuPadreId === m.id) ?
                                            (<li className="nav-item dropdown my-auto" style={{marginRight:'0.7rem'}}>
                                                    <Link className="nav-link dropdown-toggle" style={{textDecoration: 'none', color: 'white'}} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {m.nombre}
                                                    </Link>
                                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" style={{ backgroundColor: colores.rojo, maxHeight: 550 }}>
                                                        {menuHijo?.map(h => 
                                                            h.menuPadreId === m.id &&
                                                            (<li className="list-item">
                                                                {h.icon &&(<img src={ (require(`../assets/icons/${h.icon}`).default)} alt="Icono" className="icon" />)}
                                                                <Link className="children-link dropdown-item" href="#" onClick={() => handleClick(m, h)}>
                                                                    {h.nombre}
                                                                </Link>
                                                            </li>) 
                                                        )}
                                                    </ul>
                                                </li>)
                                            :
                                            m.isExternal ? 
                                            (<li className="nav-item dropdown my-auto" style={{marginRight:'1.3rem'}}>
                                                <a href={m.link} target="_blank" style={{textDecoration: 'none', color: 'white', padding:"8px 0"}}>{m.nombre}</a>
                                                
                                            </li>)
                                            :
                                            (<li className="nav-item dropdown my-auto" style={{marginRight:'1.3rem'}}>
                                                <Link href="#" 
                                                    to={m.link} style={{textDecoration: 'none', color: 'white', padding:"8px 0"}}
                                                >{m.nombre.trim() === "Carrito" ? <span>Carrito&nbsp;&nbsp;<span style={props.presupuesto?.presupuestoDetalles?.$values?.length === 0 || typeof props.presupuesto?.presupuestoDetalles?.$values?.length == 'undefined' ? {} : {borderRadius: "50%", padding: "0.2em 0.58em", border: '1px solid white'} } >{props.presupuesto?.presupuestoDetalles ? (props.presupuesto?.presupuestoDetalles?.$values?.length === 0 ? "" : props.presupuesto?.presupuestoDetalles?.$values?.length) : ""}</span></span> : m.nombre}
                                                </Link>
                                            </li>)
                                    )}
                                    {
                                    showConsulsteelMenu &&
                                        (<li className="nav-item dropdown my-auto" style={{marginRight:'1.3rem'}}>
                                            <Link style={{textDecoration: 'none', color: 'white', padding:"8px 0"}} href="#" to={rutas.CONSUL_STEEL}>
                                                consulsteel
                                            </Link>
                                        </li>)
                                    }

                                    <MenuNovedades></MenuNovedades>
                                    <MenuPerfil usuario={usuario} ></MenuPerfil>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>    
            )
            :
            (
                <nav className="navbar navbar-expand-lg static-top" style={{padding:'1rem'}}>
                    <div className="fluid" style={{display: 'flex', justifyContent: 'space-between', padding:'0rem', width:"100%", margin:"0rem 2rem"}}>
                        <Link className="navbar-brand" href="#" to={rutas.DASHBOARD}>
                            <img src={logo} alt="Barbieri logo"  height="36"></img>
                        </Link>
                    </div>
                </nav>
            )
            }
        </div>
        </>
    );
}

export default connect(
    state => ({
        hasproject: state.consulsteel.hasproject,
        hasproject_success: state.consulsteel.success,
        presupuesto: state.presupuestos.presupuesto,
        session: state.users.session,
        user: state.users.user,
        success: state.presupuestos.success,
        success_productos: state.producto.success,
        productos: state.producto.productos
    }),
    dispatch => ({
        requestPresupuestoDetalles: (contactoId, clienteId) => dispatch(requestPresupuestoDetalles(contactoId, clienteId)),
        requestOneUsersIdentification: (identificationData) => dispatch(requestOneUsersIdentification(identificationData)),
        requestProductosAllSearch: (search, clienteId) => dispatch(requestProductosAllSearch(search, clienteId)),
        hasProyectByClienteId: (clienteId) => dispatch(hasProyectByClienteId(clienteId))
    })
)(Header);