import { connect } from "react-redux";
import { Box, CircularProgress } from "@material-ui/core";
import IconButton from '@mui/material/IconButton';
import ExcelIcon from '../../assets/icons/excel-logo.png';

interface IDescargaProps {
    handleDownload: ()=> void;
    loading: boolean;
}

const Descarga = (props: IDescargaProps) => {
    return (
        <div className="col-12" style={{ display: 'flex', marginTop: "2.5vw"}}>
            <div  style={{ marginLeft: 'auto', marginRight: 0 }}> 
                <div style={{ fontSize: '20px', color: "rgba(0, 0, 0, 0.7)" }}>Baixar Excel</div>
                { props.loading ? 
                    <Box sx={{ display: 'flex',  justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                    :
                    <IconButton
                        aria-label="toggle visibility"
                        style={{ fontSize: '40px', width: "60px", marginLeft: "2vw" }} 
                        onClick={() => props.handleDownload()}
                    >
                        <img src={ExcelIcon} height={40} alt="Excel Icon" style={{ fontSize: '20px' }} />
                    </IconButton>
                }
            </div>
        </div>   
    );
};
export default connect()(Descarga);
