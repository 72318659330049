import { Wrapper, Status } from '@googlemaps/react-wrapper'; //npm i -D @types/google.maps - npm i @googlemaps/react-wrapper

const WrapperMap: React.FunctionComponent<any> = ({ children }) => {
	const key: string = process.env.REACT_APP_GOOGLE_KEY as string;
	const render = (status: Status) => {
		switch (status) {
			case Status.LOADING:
				return <h1>Carregando mapa...</h1>;
			case Status.FAILURE:
				return <h1>{status}</h1>;
			case Status.SUCCESS:
				return <>{children}</>;
		}
	};

	return <Wrapper apiKey={key} render={render} />;
};

export default WrapperMap;
