import { connect, useDispatch } from "react-redux";
import colores from "../../shared/constants/styles";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { requestDownloadFile, closeDownloadFile } from "../../actions/presupuesto";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EditarIconoBlack from "../../assets/icons/editar_black.svg";

interface IComprobantesDetalleProps {
    comprobantes: any;
    file: any;
    successDownload: any;
    puedeCargarReclamo: boolean;
    presupuestoId: string
}

const ComprobantesDetalle = (props: IComprobantesDetalleProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [downloading, setDownloading] = useState(false);

    const handleDownload = async (numero: any, ruta: any) => {
        setDownloading(true);
        dispatch(requestDownloadFile(props.presupuestoId, numero, ruta));
    };
    
    useEffect(() => {
        if (props.file !== "" && props.successDownload === true && downloading) {
            setDownloading(false);
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = `comprobante`;
            link.href = URL.createObjectURL(props.file);
            link.click();
            dispatch(closeDownloadFile());
        }
    }, [props.file, props.successDownload]);

    const handleNuevoReclamo = async (numero: string) => {
        history.push(`/nuevo-reclamo/${numero}`);
    };

    return (
        <div className="container" style={{ width: "100%" }}>
            <div
                style={{
                    backgroundColor: colores.grisClaro,
                    paddingTop: "1.20rem",
                    paddingBottom: "1.20rem",
                    margin: 0,
                }}>
                <h4 style={{ fontWeight: "lighter",  marginLeft: "1vw" }}>
                    COMPROBANTES
                </h4>
            </div>
            <div
                style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "white",
                borderRadius: "0px 0px 15px 15px",
                paddingTop: "1.20rem",
                paddingBottom: "1.20rem",
                }}>
                <div className="row row-cols-1 row-cols-sm-3 row-cols-md-5" style={{ width: "90%" }}>
                    <div className="col">
                        <p className="text-center">DATA</p>
                    </div>
                    <div className="col">
                        <p className="text-center">NÚMERO</p>
                    </div>
                    <div className="col">
                        <p className="text-center">ESTADO</p>
                    </div>
                    <div className="col">
                        <p className="text-center">CARA</p>
                    </div>
                    <div className="col">
                        <p className="text-center">DESCARGA</p>
                    </div>
                </div>
                {props.comprobantes?.$values?.map((comprobante: any) => (
                    <div
                        key={comprobante.$id}
                        className="row row-cols-1 row-cols-sm-3 row-cols-md-5"
                        style={{ width: "90%" }}
                    >
                        <div className="col" style={{ fontWeight: "bold" }}>
                            <p className="text-center">{comprobante.fecha}</p>
                        </div>
                        <div className="col" style={{ fontWeight: "bold" }}>
                            <p className="text-center">{comprobante.numero}</p>
                        </div>
                        <div className="col" style={{ fontWeight: "bold" }}>
                            <p className="text-center">{comprobante.estado}</p>
                        </div>
                        <div className="col" style={{ fontWeight: "bold" }}>
                            <p className="text-center">{comprobante.tipo}</p>
                        </div>
                        <div className="col" style={{ fontWeight: "bold" }}>
                            <p className="text-center">
                            {downloading ? (
                            "Preparando..."
                            ) : (
                            <Link
                                onClick={() =>
                                handleDownload(
                                    comprobante.numero,
                                    comprobante.link
                                )
                                }
                            >
                                <FileDownloadIcon></FileDownloadIcon>
                            </Link>
                            )}
                        </p>
                        </div>
                    </div>
                ))}
                {props.puedeCargarReclamo && (
                    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 button-iniciar-reclamo"
                        onClick={() => handleNuevoReclamo(`${props.presupuestoId}`)}
                        style={{ width: "85%", marginTop: "45px" }}>
                        <div className="col">
                            <div className="row row-cols-2 row-cols-sm-2 row-cols-md-2 button-iniciar-reclamo">
                                <div className="col">
                                    <p>COMEÇAR RECLAMAÇÕES</p>
                                </div>
                                <div className="col" style={{ marginLeft: "-12vh", marginTop: "-12px" }}>
                                    <img src={EditarIconoBlack} className="card-img-top icono-svg-black" alt="Iniciar reclamo"/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default connect(
    (state: any) => ({
        file: state.presupuestos.file,
        successDownload: state.presupuestos.successDownload,
    }),
    (dispatch) => ({
        requestDownloadFile: (numero: any, ruta: any) => dispatch(requestDownloadFile(numero, ruta)),
    })
)(ComprobantesDetalle);