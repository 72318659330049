import IColumnData from "../../../../common/interface/IColumnData";

export const FACTURA_COLUMN :IColumnData[] = [    
    {
        width: 120,
        label: 'NRO. DOCUMENTO',
        dataKey: 'documento',
    },
    {
        width: 80,
        label: 'TIPO DE DOCUMENTO',
        dataKey: 'tipo'
    },
    {
        width: 40,
        label: 'QUANTIA',
        dataKey: 'importe',
        numeric: true,
    },
    // {
    //     width: 40,
    //     label: 'MONEDA',
    //     dataKey: 'moneda',
    // },
    {
        width: 20,
        label: 'DATA DE EMISSÃO',
        dataKey: 'fechaEmision'
    },
    {
        width: 40,
        label: 'VENCIMIENTO',
        dataKey: 'vencimiento'
    },
    {
        width: 40,
        label: 'PARCELA',
        dataKey: 'parcela'
    },
    {
        width: 40,
        label: 'COMPROVANTE',
        dataKey: 'tienePdf',
        boolean: true
    }
];