import colores from "../../../shared/constants/styles";
import IFilterEstadoPedido from "./interface/IFilterEstadoPedido";
import { getEstados } from "../../../actions/cuentacorriente";
import { connect, useDispatch } from "react-redux";
import { Dayjs } from 'dayjs';
import { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid } from "@material-ui/core";

interface IFilterProps {
    filter:IFilterEstadoPedido;
    onSelectedFilter: (filter: IFilterEstadoPedido)=> void;
    getFilterEstadoPedido: ()=> void;
    estados: any;
}

const Filter = (props: IFilterProps) => {
    const dispatch = useDispatch()  
    const localStorage = JSON.parse(window.localStorage.getItem("LocalSession") || '{}');

    const [errorUI, seterrorUI] = useState({
        category: "",
        date: "",
    });    

    useEffect(() => {
        if(props.estados){
            console.log(props.estados) 
        }  
    }, [props.estados])

    const handleChangeEstado = (event: any) => {
        props.onSelectedFilter({
            ...props.filter,
            estadoId: event.target.value
        });
    };

    const handlePresupuestoToChange = (event: any) => {
        props.onSelectedFilter({
            ...props.filter,
            presupuestoId: event.target.value
        });
    };

    const handleDateFromChange = (newDate: Dayjs | null) => {
        props.onSelectedFilter({
            ...props.filter,
            dateFrom : newDate != null ? newDate: null
        })
    };

    const handleDateToChange = (newDate: Dayjs | null) => {
        props.onSelectedFilter({
            ...props.filter,
            dateTo : newDate
        })
    };

    const search = (event: any) => {
        event.preventDefault();
        props.getFilterEstadoPedido();
    }

    const handleClean= () => {
        props.onSelectedFilter({
            dateFrom: null,
            dateTo: null,
            estadoId: null,
            presupuestoId: "",
            clienteId: localStorage.clienteId.trim(),
            page: 0,
            rowPerPage:0
        })
        dispatch(getEstados());  
    }

    useEffect(() => {
        dispatch(getEstados()); 
    }, [])
    
    return (
        <div className='container-fluid mx-0 px-0 w-100' style={{margin:"2rem"}}>
            <div className="container-fluid" style={{backgroundColor: "white", width: "100%", overflow:"hidden", clear:"both", marginBottom: "1.85rem"}}>
                <form className="row row-cols-1 row-cols-sm-8 row-cols-md-8" onSubmit={search} style={{margin:"1vw"}}>
                    <div>
                        <div className="row">
                            <div className='col-4'>
                                <FormControl fullWidth>
                                    <InputLabel id="estadoId">Estados</InputLabel>
                                    <Select                          
                                    labelId="estadoId"
                                    id="estados"
                                    value={props.filter.estadoId}
                                    label="Estados"
                                    onChange={handleChangeEstado}
                                    >
                                    {props.estados?.map((estado: any) => (
                                        <MenuItem value={estado.id}>{estado.descripcion}</MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-2">
                                <FormControl fullWidth>
                                    <TextField
                                        id="presupuesto"
                                        value={props.filter.presupuestoId}
                                        name="presupuesto"
                                        onChange={handlePresupuestoToChange}
                                        label="Presupuesto"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <div className="col-3">
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider
                                        adapterLocale="es"
                                        dateAdapter={AdapterDayjs}
                                        >
                                        <MobileDatePicker
                                            label="Desde"
                                            inputFormat="DD/MM/YYYY"
                                            value={props.filter.dateFrom}
                                            onChange={(newValue) => handleDateFromChange(newValue)}
                                            renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={Boolean(errorUI?.date)}
                                            />
                                            )}
                                        />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </div>
                            <div className="col-3">
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider
                                        adapterLocale="es"
                                        dateAdapter={AdapterDayjs}
                                        >
                                        <MobileDatePicker
                                            label="Hasta"
                                            inputFormat="DD/MM/YYYY"
                                            value={props.filter.dateTo}
                                            onChange={(newValue) => handleDateToChange(newValue)}
                                            renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={Boolean(errorUI?.date)}
                                            />
                                            )}
                                        />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </div>
                        </div>
                    </div>      
                <div style={{margin:"1vw"}}>
                    <button type="submit" style={{background: colores.rojo, color: 'white'}} className="btn">Buscar</button>
                    <button style={{background: colores.white, color: 'red', borderColor: 'red', marginLeft: '2vh'}} className="btn" onClick={handleClean}>Limpar</button>
                </div>
                </form>                
            </div>
        </div>
    );
};
export default connect(
    (state:any) => ({
        estados: state.cuentacorriente.estados,
        loading: state.cuentacorriente.loading,
    }),
    dispatch => ({
        getEstados: () => dispatch(getEstados()),
    })
)(Filter);
